<template>
  <div>
    <a-alert
      v-if="errorMessage"
      type="error"
      :message="errorMessage"
      banner
      closable
      @close="errorMessage = null"
    />

    <!-- filter -->
    <ARow :gutter="[8, 8]">
      <ACol :xl="6" :md="8" :sm="12" :span="24">
        <ADatePicker
          v-model:value="state.params.start_date"
          :disabled-date="disabledStartDate"
          placeholder="Tanggal Mulai"
          style="width:100%;"
          format="DD MMMM YYYY"
        />
      </ACol>
      <ACol :xl="6" :md="8" :sm="12" :span="24">
        <ADatePicker
          v-model:value="state.params.end_date"
          :disabled-date="disabledEndDate"
          placeholder="Tanggal Akhir"
          style="width:100%;"
          format="DD MMMM YYYY"
        />
      </ACol>
      <ACol :xl="6" :md="8" :sm="12" :span="24">
        <FilterSalesman style="width:100%;" v-model:value="state.params.salesman" />
      </ACol>
      <ACol :xl="6" :md="8" :sm="12" :span="24">
        <FilterToko style="width:100%;" mode="multiple" v-model:value="state.params.toko" />
      </ACol>
      <ACol :xl="6" :md="8" :sm="12" :span="24">
        <AButton type="primary" title="cari" @click="fetchDataList" :loading="state.isFetching">
          <span v-if="!state.isFetching" class="fa fa-search" aria-hidden="true" />
          <span v-else>
            Memuat Data ...
          </span>
        </AButton>
      </ACol>
    </ARow>

    <div class="row justify-content-end mt-4">
      <div class="col-lg-6 col-md-12"></div>
      <div class="col-lg-6 col-md-12 text-right">
        <DownloadExcel
          :url="state.endpoint"
          :params="queryParams()"
          namefile="Detail-Survey-Proyek"
          @errors="errorMessage"
        />
      </div>
    </div>

    <!-- list table -->
    <div class="table-responsive text-nowrap mt-4">
      <TableStandard
        v-if="!state.isFetching"
        :url="state.endpoint"
        :columns="state.columns"
        :params="queryParams()"
        @errors="errorMessage"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, nextTick } from 'vue'
import FilterSalesman from '@/components/filter/FilterSalesman'
import FilterToko from '@/components/filter/FilterToko'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import TableStandard from '@/components/Molecules/TableStandard'
import moment from 'moment'
import { updateUrlWithQuery, extractQueryParams } from '@/helpers/globalmixin'

export default defineComponent({
  components: {
    FilterSalesman,
    FilterToko,
    DownloadExcel,
    TableStandard,
  },
  setup() {
    const errorMessage = ref()
    const state = reactive({
      columns: [
        {
          title: 'NO',
          slots: { customRender: 'no' },
        },
        {
          title: 'Tanggal Visit',
          dataIndex: 'survey_date',
        },
        {
          title: 'No Visit',
          dataIndex: 'no_survey',
        },
        {
          title: 'Kode Salesman',
          dataIndex: 'salesman_id',
        },
        {
          title: 'Nama Salesman',
          dataIndex: 'fullname',
        },
        {
          title: 'ID Toko',
          dataIndex: 'customer_code',
        },
        {
          title: 'Nama Toko',
          dataIndex: 'customer_name',
        },
        {
          title: 'Nama Proyek',
          dataIndex: 'project_name',
        },
        {
          title: 'Kategori',
          dataIndex: 'project_category',
        },
        {
          title: 'Tanggal Mulai Proyek',
          dataIndex: 'start_date',
        },
        {
          title: 'Tanggal Akhir Proyek',
          dataIndex: 'end_date',
        },
        {
          title: 'Produsen',
          dataIndex: 'supplier_name',
        },
        {
          title: 'Merk/Brand',
          dataIndex: 'brand',
        },
        {
          title: 'Kemasan/Produk',
          dataIndex: 'product',
          customRender: ({ text }) => {
            const products = JSON.parse(text)
            return products.map(item => item.product_name).join(', ')
          },
        },
        {
          title: 'Volume',
          dataIndex: 'volume',
        },
      ],
      endpoint: '/api/report-ats/detail-survey-project-v2',
      data: [],
      meta: {
        per_page: 10,
        page: 1,
        total: 0,
      },
      isFetching: false,
      params: extractQueryParams({
        q: '',
        start_date: moment(new Date()).startOf('month'),
        end_date: moment(new Date()).endOf('month'),
        salesman: [],
        toko: [],
        page: 1,
        'per-page': 10,
      }),
    })

    const disabledStartDate = current => {
      return current && current > moment(state.params.end_date)
    }

    const disabledEndDate = current => {
      return current && moment(state.params.start_date) >= current
    }

    const queryParams = () => {
      let params = state.params

      if (state.params.start_date) {
        state.params.start_date = moment(state.params.start_date).format('YYYY-MM-DD')
      }

      if (state.params.end_date) {
        state.params.end_date = moment(state.params.end_date).format('YYYY-MM-DD')
      }

      return updateUrlWithQuery(params)
    }

    // fetch list data
    const fetchDataList = () => {
      state.isFetching = true

      nextTick().then(() => {
        state.isFetching = false
      })
    }

    return {
      state,
      errorMessage,
      queryParams,
      fetchDataList,
      disabledStartDate,
      disabledEndDate,
    }
  },
})
</script>
